
<template>
  <div class="ele-body">
    <el-tabs v-model="activeName" >
      <el-tab-pane label="车辆颜色设置" name="first">
        <el-card shadow="never" style="width: 100%; height: 690px;">
          <div class="content">
            <div class="vehicleList">
              <template v-if="vehicleList.length > 0">
                <div class="vehicleList_li" v-for="(item, index) in vehicleList" :key="index">
                  <div>颜色{{ item.title }}：</div>
                  <el-input v-model="item.car_color" placeholder="请填写车辆颜色" class="input163" />
                </div>
              </template>
              <template v-else>
                <div class="noVehicleList">暂无车辆颜色</div>
              </template>
            </div>
            <div class="operation">
              <div class="add_reduce">
                <img src="../../../assets/zengjia.png" @click="addColor()">
              </div>
              <div class="add_reduce">
                <img src="../../../assets/jianshao.png" @click="reduceColor()">
              </div>
            </div>
          </div>
          <el-button class="save" @click="vehicleColorSave()">确认并保存</el-button>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="电子围栏设置" name="second">
        <el-card shadow="never" style="width: 100%; height: 690px;">
          <el-button class="add_fence" @click="addFence()">添加电子围栏</el-button>
          <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 260px)" highlight-current-row :stripe=true>
            <template slot-scope="{index}">
              <el-table-column type="index" :index="index" label="序号" width="150" align="center" fixed="left"
                show-overflow-tooltip />
              <el-table-column prop="province.name" label="所属区域" align="center" show-overflow-tooltip min-width="250">
                <template slot-scope="{row}">{{ row.province.name}}{{ row.city.name }}{{ row.area.name }}</template>
              </el-table-column>
              <el-table-column prop="color.car_color" label="车辆颜色" align="center" show-overflow-tooltip min-width="250">
                <template slot-scope="{row}">
                  <span v-for="(item , index) in row.color" :key="index">
                    {{ item.car_color }}、
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="开启状态" align="center" show-overflow-tooltip min-width="100">
                <template slot-scope="{row}" >
                  <div class="shut_down" v-if="row.status == 0">关闭中</div>
                  <div class="open" v-if="row.status == 1">开启中</div>
                </template>
              </el-table-column>
              <el-table-column prop="update_time" label="更新时间" align="center" show-overflow-tooltip min-width="200" />
              <el-table-column prop="status" label="操作" width="300px" align="center" :resizable="false" fixed="right">
                <template slot-scope="{row}" >
                  <el-button type="primary" size="small" @click="edit(row.id)" v-if="permission.includes('sys:vehicleFence.vue:edit')">编辑</el-button>
                  <el-button type="success" size="small" v-if="row.status == 0" @click="control(row.id,row.status)">开启</el-button>
                  <el-button type="warning" size="small" v-if="row.status == 1" @click="control(row.id,row.status)">关闭</el-button>
                  <el-button type="danger" size="small" @click="fenceDel(row.id)" v-if="permission.includes('sys:vehicleFence.vue:delete')">删除</el-button>
                </template>
              </el-table-column>
            </template>
          </ele-data-table>
          <el-dialog v-dialogDrag :title="editForm.id ? '编辑电子围栏' : '添加电子围栏'" :visible.sync="show" :destroy-on-close="true" :lock-scroll="false"
          @closed="editForm = {}" width="900px" @close="refreshData">
            <el-form label-width="200px" size="mini" class="ele-form-detail" v-if="editForm.id">
              <div style="display: flex;">
                <el-form-item label="所属区域：" label-width="100px" class="w-250">
                  <el-select v-model="province" @change="handleChangeProv1(province)" placeholder="请选择省"
                    clearable>
                    <el-option v-for="option in provArr" :label="option.name" :value="option.pid"
                      :key="option.pid"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="15px" class="w-170">
                  <el-select v-model="city" @change="handleChangeCity1(city)"
                    placeholder="请选择市" clearable>
                    <el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
                      :key="option.cid"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="15px" class="w-170">
                  <el-select v-model="area" @change="$forceUpdate(), handleDis(area)" placeholder="请选择县/区" clearable>
                    <el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
                      :key="option.aid"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item label="车辆颜色：" label-width="100px">
                <el-select class="car_color" v-model="vehicleColor" @change="changeValue" multiple
                  placeholder="请选择车辆颜色（可单选或多选）">
                  <el-option v-for="option in vehicleList" :label="option.car_color" :value="option.id"
                    :key="option.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-form label-width="200px" size="mini" class="ele-form-detail" v-if="!editForm.id">
              <div style="display: flex;">
                <el-form-item label="所属区域：" label-width="100px" class="w-250">
                  <el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)" placeholder="请选择省"
                    clearable>
                    <el-option v-for="option in provArr" :label="option.name" :value="option.pid"
                      :key="option.pid"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="15px" class="w-170">
                  <el-select v-model="editForm.cid" @change="handleChangeCity1(editForm.cid)"
                    placeholder="请选择市" clearable>
                    <el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
                      :key="option.cid"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="15px" class="w-170">
                  <el-select v-model="editForm.aid" @change="$forceUpdate(), handleDis(editForm.aid)" placeholder="请选择县/区" clearable>
                    <el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
                      :key="option.aid"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item label="车辆颜色：" label-width="100px">
                <el-select class="car_color" v-model="vehicleColor" @change="changeValue" multiple
                  placeholder="请选择车辆颜色（可单选或多选）" clearable>
                  <el-option v-for="option in vehicleList" :label="option.car_color" :value="option.id"
                    :key="option.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" style=" width: 100%;background-color:#F4F4F4 ;height: 100%;margin-top: -30px;">
              <div class="map">
                <div class="electron_fence">电子围栏：</div>
                <div id="container"></div>
              </div>
              <div style="display: flex;justify-content: space-between;">
                <el-button class="fence_save" @click="fenceSave(editForm.id)">确认并保存</el-button>
                <div style="display: flex;align-items: center;margin-right: 65px;">
                  <!-- <el-button @click="createPolygon()">新建</el-button> -->
                  <el-button @click="polyEditor.open();">开始编辑</el-button>
                  <el-button @click="polyEditor.close()">结束编辑</el-button>
                </div>
              </div>
            </div>
          </el-dialog>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
	mapGetters
} from "vuex";
const AMap = window.AMap;
export default {
  name: "VehicleFence",
  data() {
    return {
      table: { url: '/geo_fence/fenceList', where: {} },  // 表格配置
      choose: [],  // 表格选中数据
      editForm: {},  // 表单数据
      checkForm: {},
      show: false,
      activeName: 'first',
      map: '',
      polyEditor: '',
      provArr: [],
      cityArr: [],
      colorArr: [],
      districtArr: [],
      vehicleList: [],
      vehicleColor: [],
      path: [],
      points: [],
      province:'',
      city:'',
      area:'',
      district: null,
      name: '',
    }
  },
  created() {
    this.$http.get('/common/province_list').then(res => {
			let data = JSON.parse(res.data)
			this.provArr = data
		})
  },
  computed: {
		...mapGetters(["permission"]),
	},
  mounted() {
    this.getColorList();
    setTimeout(() => {
      this.initMap() // 异步加载（否则报错initMap is not defined）
      // this.initroad()
    }, 1000)
  },
  methods: {
    //获取车辆颜色列表
    getColorList() {
      this.$http.get('/geo_fence/colorList').then(res => {
        //console.log('获取车辆颜色列表的res', res);
        if(res.data.code == 0){
          this.vehicleList = [];
          res.data.data.forEach((item , index) => {
            let title = this.SectionToChinese(index + 1);
            this.vehicleList.push(Object.assign({},item,{ title }));
          });
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    //颜色后缀数值
    SectionToChinese(section) {
      var chnNumChar = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
      var chnUnitChar = ["", "十", "百", "千", "万", "亿", "万亿", "亿亿"];
      var strIns = '', chnStr = '';
      var unitPos = 0;
      var zero = true;
      while (section > 0) {
        var v = section % 10;
        if (v === 0) {
          if (!zero) {
            zero = true;
            chnStr = chnNumChar[v] + chnStr;
          }
        } else {
          zero = false;
          strIns = chnNumChar[v];
          strIns += chnUnitChar[unitPos];
          chnStr = strIns + chnStr;
        }
        unitPos++;
        section = Math.floor(section / 10);
      }
      return chnStr;
    },
    //添加车辆颜色
    addColor(){
      let title = this.SectionToChinese(this.vehicleList.length+1)
      this.vehicleList.push({car_color:'',title})
    },
    //减少、删除车辆颜色
    reduceColor(){
      this.vehicleList.pop()
    },
    //保存车辆颜色列表
    vehicleColorSave(){
      this.$http.post('/geo_fence/colorStore',{
        colors: this.vehicleList,
      }).then(res => {
        //console.log('保存车辆颜色列表的res', res);
        if(res.data.code == 0){
          this.$message({
            showClose: true,
            message: '保存成功',
            type: 'success'
          });
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'warning',
          });
        }
        
        this.getColorList()
      })
    },
    //添加电子围栏弹窗
    addFence() {
      this.show = true;
      this.path = [];
      this.initMap();
    },
    // 初始化地图
    initMap() {
      let that = this;
      var map = new AMap.Map('container', {
        resizeEnable: true, // 窗口大小调整
        center: [116.478935, 39.997761], // 中心 firstArr: [116.478935, 39.997761],
        zoom: 14
      })
      that.map = map;
      var polyEditor = new AMap.PolygonEditor(that.map);
      that.polyEditor = polyEditor;
      this.polygon()
    },
    //多边形围栏
    polygon(){
      //console.log('多边形围栏');
      let that = this;
      //console.log('that.path',that.path);
      let center = that.getCenter();
      // //console.log('中心点坐标',center);
      if(that.path.length != 0){
        //计算西南，东北坐标得到zoom级别和中心坐标点
        let maxx, maxy, minx, miny;
        maxx = that.path[0][1]
        maxy = that.path[0][0]
        minx = that.path[0][1]
        miny = that.path[0][0]

        for (let index = 0; index < that.path.length; index++) {
          const element = that.path[index];
          
          if (maxx < element[1]) {
            maxx = element[1]
          }

          if (maxy < element[0]) {
            maxy = element[0]
          }

          if (minx > element[1]) {
            minx = element[1]
          }

          if (miny > element[0]) {
            miny = element[0]
          }
        }

        //console.log('东南西北',maxx,maxy,minx,miny);
        // that.map.getFitZoomAndCenterByOverlays(that.path,center,16)
        // //console.log('that.path==>',that.path);
        // let Bounds = ;
        // //console.log('Bounds==>',Bounds);
        setTimeout(() => {
          //通过getFitZoomAndCenterByBounds方法得出zoom级别和中心点
          let zoom = that.map.getFitZoomAndCenterByBounds(new AMap.Bounds(new AMap.LngLat(miny, minx), new AMap.LngLat(maxy, maxx)),[70,70,70,70],20);
          //console.log('zoom',zoom);
          var map = new AMap.Map('container', {
            resizeEnable: true, // 窗口大小调整
            center, // 中心 firstArr: [116.478935, 39.997761],
            zoom: zoom[0]
          })
          that.map = map;
          var polyEditor = new AMap.PolygonEditor(that.map); 
          that.polyEditor = polyEditor;
          var initPolygon = new AMap.Polygon({
            path: that.path
          })
          //添加显示已有多边形围栏
          that.map.add(initPolygon);
          that.polyEditor.setTarget(initPolygon);
          // polyEditor.addAdsorbPolygons(initPolygon);
          initPolygon.on('dblclick', () => {
            that.polyEditor.setTarget(initPolygon);
            that.polyEditor.open();
          })
          that.polyEditor.on('add', function (data) {
            //console.log('data==>>>>', data);
            var polygon = data.target;
            that.polyEditor.addAdsorbPolygons(polygon);
            polygon.on('dblclick', () => {
              that.polyEditor.setTarget(polygon);
              that.polyEditor.open();
            })
          })
          that.polyEditor.on('end', function (event) {
            // event.target 即为编辑后的多边形对象,event.target.getPath()得到编辑完成后的点数组
            let pointArr = event.target.getPath()
            let polygonPaths = []
            for (let i = 0; i < pointArr.length; i++) {
              polygonPaths.push({ lat: pointArr[i].lat, lng: pointArr[i].lng })
            }
            that.points = polygonPaths;
            //console.log('polygonPaths多边形坐标点', that.points)
          })
        })
      }
      that.polyEditor.on('add', function (data) {
        //console.log('data==>>>>', data);
        var polygon = data.target;
        that.polyEditor.addAdsorbPolygons(polygon);
        polygon.on('dblclick', () => {
          that.polyEditor.setTarget(polygon);
          that.polyEditor.open();
        })
      })
      that.polyEditor.on('end', function (event) {
        // event.target 即为编辑后的多边形对象,event.target.getPath()得到编辑完成后的点数组
        let pointArr = event.target.getPath()
        let polygonPaths = []
        for (let i = 0; i < pointArr.length; i++) {
          polygonPaths.push({ lat: pointArr[i].lat, lng: pointArr[i].lng })
        }
        that.points = polygonPaths;
        //console.log('polygonPaths多边形坐标点', that.points)
      })
    },
    //取面向对象中心点
    getCenter() {
      let total = this.path.length;
      let X = 0; let Y = 0; let Z = 0;
      this.path.forEach((lnglat) => {
          let lng = lnglat[0] * Math.PI / 180;
          let lat = lnglat[1] * Math.PI / 180;
          let x, y, z;
          x = Math.cos(lat) * Math.cos(lng);
          y = Math.cos(lat) * Math.sin(lng);
          z = Math.sin(lat);
          X += x;
          Y += y;
          Z += z;
      });
      X = X / total;
      Y = Y / total;
      Z = Z / total;

      let Lng = Math.atan2(Y, X);
      let Hyp = Math.sqrt(X * X + Y * Y);
      let Lat = Math.atan2(Z, Hyp);
      return [Lng * 180 / Math.PI, Lat * 180 / Math.PI];
    },
    //开启或关闭
    control(id,start){
      // //console.log('开启或关闭',id,start);
      let status = start;
      if(status == 0){
        status = 1
      } else {
        status = 0
      }
      // //console.log('status',status);
      this.$http.post('/geo_fence/fenceStatus', {
        id,
        status
      }).then(res => {
        //console.log('开启或关闭的res',res);
        this.reload()
      })
    },
     /**
     *选择省
      **/
    // handleChangeProv(e) {
    //   /** 获取被选省份的pid**/
    //   let pid = ''
    //   this.provArr.forEach(function (item) {
    //       if (item.pid == e) {
    //           pid = item.pid
    //       }
    //   })
    //   /** 根据被选省份的pid获取省市下面的市**/
    //   this.$http.post('/common/city_list', {
    //       pid: pid
    //   }).then(res => {
    //       let data = JSON.parse(res.data)
    //       this.cityArr = data
    //       /** 选择省份清空市县**/
    //       this.table.where.cid = ''
    //       this.table.where.aid = ''
    //   })
    // },
    /**
     *选择市
      **/
    // handleChangeCity(e) {
    //   if (e) {
    //       /** 获取被选市的cid**/
    //       let cid = ''
    //       this.cityArr.forEach(function (item) {
    //           if (item.cid == e) {
    //               cid = item.cid
    //           }
    //       })
    //       /** 根据被选市的cid获取市下面的县**/
    //       this.$http.post('/common/area_list', {
    //           cid: cid
    //       }).then(res => {
    //           let data = JSON.parse(res.data)
    //           this.districtArr = data
    //           /** 选择省份清空县**/
    //           this.table.where.aid = ''
    //       })
    //   } else {
    //       // this.table.where.pid = ''
    //       this.table.where.aid = ''
    //   }
    // },
    /**
     *修改省
      **/
    handleChangeProv1(e) {
      // //console.log('e',e);
      /** 获取被选省份的pid**/
      if (e) {
        //console.log('e',e);
          var pid = '';
          var pname = '';
          this.city = '';
          this.area = '';
          let that = this;
          this.changePro = true;
          this.provArr.forEach(function (item) {
              if (item.pid == e) {
                  pid = item.pid;
                  pname = item.name;
              }
          })
          /** 根据被选省份的pid获取省市下面的市**/
          this.$http.post('/common/city_list', {
              pid: pid
          }).then(res => {
              let data = JSON.parse(res.data)
              that.cityArr = data;
              this.editForm.pid = pid;
              this.editForm.pname = pname;
              /** 选择省份清空市县**/
              this.editForm.cid = ''
              this.editForm.aid = ''
              this.editForm.cname = ''
              this.editForm.aname = ''
          })
      } else {
          this.editForm.pid = ''
          this.editForm.cid = ''
          this.editForm.aid = ''
          this.editForm.pname = ''
          this.editForm.cname = ''
          this.editForm.aname = ''
      }
    },
    /**
     *修改市
      **/
    handleChangeCity1(e) {
      if (e) {
          /** 获取被选市的cid**/
          var cid = '';
          var cname = '';
          this.changeCity = true
          this.cityArr.forEach(function (item) {
              if (item.cid == e) {
                  cid = item.cid;
                  cname = item.name
              }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
              cid: cid
          }).then(res => {
              let data = JSON.parse(res.data)
              this.districtArr = data;
              this.editForm.cname = cname;
              /** 选择省份清空县**/
              this.editForm.aid = ''
              this.editForm.aname = ''
          })
      } else {
          // this.editForm.cname = ''
          this.editForm.aid = ''
          this.editForm.aname = ''
      }
    },
    handleDis(e) {
      //console.log('e',e);
      var aid = '';
      var aname = '';
      this.districtArr.forEach(function (item) {
        if (item.aid == e) {
          aid = item.aid;
          aname = item.name
        }
      })
      this.editForm.aname = aname;
      //console.log('aname',aname);
      this.geoCode()
      
    },
    //根据地区跳转相应的地区
    geoCode() {
      let that = this;
      var address = this.editForm.pname + this.editForm.cname + this.editForm.aname + '政府';
      var geocoder = new AMap.Geocoder({
        city: "", //城市设为北京，默认：“全国”
      });
      //console.log('address',address);
      geocoder.getLocation(address, function(status, result) {
        //console.log('geocoder');
        if (status === 'complete'&& result.geocodes.length) {
            var center = [result.geocodes[0].location.lng,result.geocodes[0].location.lat]
            //console.log('center',center);
            var map = new AMap.Map('container', {
              resizeEnable: true, // 窗口大小调整
              center, // 中心 firstArr: [116.478935, 39.997761],
              zoom: 16
            })
            that.map = map;
            var polyEditor = new AMap.PolygonEditor(that.map);
            that.polyEditor = polyEditor;
            // that.polygon()
            that.polyEditor.on('add', function (data) {
              //console.log('data==>>>>', data);
              var polygon = data.target;
              that.polyEditor.addAdsorbPolygons(polygon);
              polygon.on('dblclick', () => {
                that.polyEditor.setTarget(polygon);
                that.polyEditor.open();
              })
            })
            that.polyEditor.on('end', function (event) {
              // event.target 即为编辑后的多边形对象,event.target.getPath()得到编辑完成后的点数组
              let pointArr = event.target.getPath()
              let polygonPaths = []
              for (let i = 0; i < pointArr.length; i++) {
                polygonPaths.push({ lat: pointArr[i].lat, lng: pointArr[i].lng })
              }
              that.points = polygonPaths;
              //console.log('polygonPaths多边形坐标点', that.points)
            })
        }else{
          //console.log('根据地址查询位置失败');
        }
      });
    },
    //编辑添加弹窗取消
    refreshData(){
      this.vehicleColor = [];
    },
    //列表刷新
    reload(){
      this.table.where = {};
      this.$refs.table.reload()
    },
    //下拉选择点击响应事件获取id
    changeValue(event){
      // //console.log('e>>',event);
    },
    //编辑电子围栏
    edit(id){
      // //console.log('id,颜色列表',id);
      this.$http.post('/geo_fence/fenceShow',{
        id,
      }).then(res => {
        //console.log('编辑围栏的res', res);
        this.editForm = res.data.data;
        this.colorArr = res.data.data.color;
        this.province = res.data.data.province.name;
        this.city = res.data.data.city.name;
        this.area = res.data.data.area.name;
        res.data.data.color.forEach(item => {
          this.vehicleColor.push(item.id)
        })
        var pointsArr = [];
        res.data.data.points.forEach(item => {
          pointsArr.push([item.lng*1,item.lat*1])
        });
        this.polyEditor.open();
        this.polyEditor.close();
        //获取围栏坐标点
        this.path = pointsArr;
        // //console.log('pointsArr',pointsArr);
        this.show = true;
        this.polygon()
      })
    },
    //删除电子围栏
    fenceDel(id){
      let that = this;
      this.$confirm('确定要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        that.$http.post('/geo_fence/fenceDelete',{
            id,
          }).then(res => {
            //console.log('删除围栏的res', res);
            if(res.data.code == 0){
              that.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.reload()
            } else {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'warning'
              });
            }
          })
      }).catch(() => {
        //console.log('取消删除');
      });
      
    },
    // 编辑/保存电子围栏
    fenceSave(id) {
      this.polyEditor.close();
      if(id){
        this.$http.post('/geo_fence/fenceStore',{
          id,
          pid: this.editForm.pid ? this.editForm.pid : this.province,
          cid: this.editForm.cid ? this.editForm.cid : this.city,
          aid: this.editForm.aid ? this.editForm.aid : this.area,
          color: this.vehicleColor,
          points: this.points
        }).then(res => {
          //console.log('编辑保存围栏的res', res);
          if(res.data.code == 0){
            this.$message({
              showClose: true,
              message: '保存成功',
              type: 'success'
            });
            this.show = false;
            this.reload()
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'warning'
            });
          }
        })
      } else {
        this.$http.post('/geo_fence/fenceStore',{
          id,
          pid: this.editForm.pid,
          cid: this.editForm.cid,
          aid: this.editForm.aid,
          color: this.vehicleColor,
          points: this.points,
        }).then(res => {
          //console.log('保存围栏的res', res);
          if(res.data.code == 0){
            this.$message({
              showClose: true,
              message: '保存成功',
              type: 'success'
            });
            this.show = false;
            this.reload()
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'warning',
            });
          }
        })
      }
    },
  },
}
</script>
  
<style scoped>
/deep/.el-tabs__item {
  padding: 0 20px !important;
  font-size: 15px;
  https: //www.uyl.cn/
}

/deep/.el-tabs__nav-scroll {
  background: white !important;
  padding-top: 10px;
}

/deep/.el-dialog__footer {
  padding: 0;
  text-align: left;
}

.myClass{
  color: red;
  font-size: 30px;
  line-height: 100px;
  line-height: 50px;
}

.content {
  display: flex;
}

.noVehicleList{
  font-size: 18px;
  display: flex;
  align-items: center;
}

.vehicleList {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
}

.vehicleList_li {
  margin: 10px 20px;
  display: flex;
  align-items: center;
}

.operation {
  margin: 10px 0;
  display: flex;
}

.add_reduce img {
  width: 30px;
  height: 30px;
  margin: 3px 5px;
}

.save {
  color: #FFF;
  border: #409EFF;
  background-color: #409EFF;
  margin-top: 30px;
}

.fence_save {
  color: #FFF;
  border: #409EFF;
  background-color: #409EFF;
  margin: 20px 0 20px 110px;
}

.add_fence {
  color: #409EFF;
  border: 1px solid #409EFF;
  margin: 10px 0 25px 0;
}

.car_color {
  width: 490px;
}

.electron_fence {
  width: 85px;
  font-size: 14px;
  margin-left: 28px;
}

.map {
  display: flex;
  margin: 15px 0;
}

#container {
  position: relative;
  width: 80%;
  height: 50vh;
  background-color: white;
}
.shut_down{
  color: #FF0505;
}
.open{
  color: #06D91A;
}
</style>
  
  